$(document).on('turbolinks:load', async () => {

  async function get_area(pref_id) {
    let res = await fetch(location.protocol + "//" +  location.host + "/api/area/" + pref_id, {
      method: "get",
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (res.ok) {
      let data = await res.json();
      return data
    } else {
      console.log(res)
    }
  }

  $('select#q_prefecture_code_eq').on('change', async function() {
    let menuList = document.getElementById('area');
    menuList.disabled = false;
    menuList.innerHTML = '';
    let option = document.createElement('option');
    option.innerHTML = '駅名';
    option.defaultSelected = true;
    option.disabled = true;
    menuList.appendChild(option);  
    
    let area = await get_area(this.value)
    area.forEach( menu => {
      let option = document.createElement('option');
      option.innerHTML = menu[1];
      option.value = menu[0];
      menuList.appendChild(option);  
    });    
  })
});